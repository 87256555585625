import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import FeedbackService from '../service/FeedbackService';
import NotifyController from '../utilities/Toast';
import { genTimePerJob } from '../utilities/util';
import { getEnvironment } from '../service/LocalStorageService';

const GeneratedImage = () => {
    const navigate = useNavigate();
    const { page } = useParams();
    const [ready, setReady] = useState(false);

    const [searchParams] = useSearchParams();
    const [tableOptions, _] = useState<{
        rowPerPage: number;
        filter: any;
        filters?: any;
        page: number;
        order?: string;
        orderType?: 1 | 0 | -1 | undefined | null;
        selectedCol?: string | null;
    }>(searchParams.get('query') ? JSON.parse(decodeURIComponent(searchParams.get('query') as string)) : sessionStorage.getItem('gi_query') ? JSON.parse(sessionStorage.getItem('gi_query') as string) : undefined);

    const [isShowDailyGenResetModal, setIsShowDailyGenResetModal] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<{
        device_token: string;
    }>({
        defaultValues: {
            device_token: ''
        }
    });

    const onOptionChange = async (option: tableOptions) => {
        navigate(`?query=${encodeURIComponent(JSON.stringify(option))}`);
        sessionStorage.setItem('gi_query', JSON.stringify(option));

        if (option.filter && option.selectedCol === 'is_sub') {
            if (['true', 'false'].indexOf(option.filter) < 0) {
                NotifyController.error(`Please fill search box with "true" or "false with column is_sub`);
                return { total: 0, data: [] };
            }
        }

        const feedbacks = await FeedbackService.getInstance()
            .getListGeneratedImage(option.page, option.rowPerPage, option.orderType, option.order, option.filter, option.selectedCol)
            .catch((error) => {
                NotifyController.error(error?.message);
                console.log(error);
            });
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items };
    };

    const bodyAssetUrl = (rowData: any) => {
        return rowData.url_v2?.map((item: any) => {
            return (
                <div className="p-3">
                    <Image src={item} alt={item} width="120" preview />
                </div>
            );
        });
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        if (!timestamp) return <span></span>;

        // Convert the timestamp using moment and adjust for GMT+7 timezone
        const formattedDateTime = moment(timestamp)
            .utcOffset(7) // Set offset for GMT+7
            .format('YYYY-MM-DD HH:mm:ss'); // Format the date as 'yyyy-MM-dd HH:mm:ss'

        return <span>{formattedDateTime}</span>;
    };

    const bodyQueueTime = (rowData: any) => {
        return genTimePerJob(rowData.sub_queue_time ?? [0])?.join('  ');
    };

    const bodyDevicePlatform = (rowData: any) => {
        if (rowData.device_token?.length === 16) {
            return 'Android';
        } else if (rowData.device_token?.length === 36) {
            return 'IOS';
        } else {
            return 'Unknown';
        }
    };

    const bodyGenTime = (rowData: any) => {
        return genTimePerJob(rowData.sub_progress_time ?? [0])?.join('  ');
    };

    const handleDetailButtonClick = (rowData: any) => {
        navigate(`/generated-image/detail/${rowData._id}`);
    };

    const handlePageChange = (page: any) => {
        navigate(`/generated-image/${page}`);
    };

    const onResetDailyGenBtnClicked = () => {
        setIsShowDailyGenResetModal(true);
    };

    const onClose = () => {
        setIsShowDailyGenResetModal(false);
    };

    const onResetDailyGenSubmitted = (formData: any) => {
        FeedbackService.getInstance()
            .resetDailyGen({ device_token: formData.device_token.trim() })
            .then(() => {
                reset();
                NotifyController.success('Reset successfully');
                onClose();
            })
            .catch((error) => {
                NotifyController.error(error?.response?.data?.message ?? error?.message);
                onClose();
            });
    };

    useEffect(() => {
        if (!ready) {
            FeedbackService.getInstance()
                .getListGeneratedImage(1, 10)
                .then(() => setReady(true));
        }
    }, [ready]);

    if (!ready) {
        return null;
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        page={parseInt(page ?? '1')}
                        handlePageChange={handlePageChange}
                        tableOption={tableOptions}
                        rightToolbarBtn={getEnvironment() === 'production' ? undefined : <Button label="Reset Daily-Gen" icon="pi pi-trash" className="p-button-danger ml-4" onClick={onResetDailyGenBtnClicked} />}
                    >
                        <Column body={(rowData: any) => <Button onClick={() => handleDetailButtonClick(rowData)}>Detail</Button>} header="Action" headerStyle={{ width: '6rem', textAlign: 'center' }} style={{ textAlign: 'center' }} />
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="is_sub" header="Is Sub" sortable filter headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="url_v2" body={bodyAssetUrl} header="Asset" headerStyle={{ width: 120 }}></Column>
                        <Column field="platform" body={bodyDevicePlatform} header="Platform" headerStyle={{ width: 120 }}></Column>
                        <Column field="status" header="Status" sortable headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="device_token" header="Device ID" sortable headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="reason" header="Reason" sortable headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="sub_queue_time" header="Queue Time" body={bodyQueueTime} sortable headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="sub_progress_time" header="Gen Time" body={bodyGenTime} sortable headerStyle={{ minWidth: '10rem' }}></Column>
                    </CustomDataTable>
                </div>
            </div>

            {isShowDailyGenResetModal && (
                <Dialog header="Reset daily image generation" visible={true} style={{ width: '50vw' }} onHide={() => onClose()}>
                    <form onSubmit={handleSubmit(onResetDailyGenSubmitted)}>
                        {/* Form */}
                        <div className="p-fluid">
                            <div className="p-field mb-3">
                                <label htmlFor="device_token">Device Token</label>
                                <InputText id="device_token" className='my-2' {...register('device_token', { required: 'The device_token is required' })} />
                                {errors.device_token && <span style={{color: '#e86258', fontSize: '14px'}}>{errors.device_token.message}</span>}
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="p-mt-2">
                            <Button label="Submit" type="submit" className="p-button-success mr-3" />
                            <Button label="Cancel" onClick={() => onClose()} className="p-button-secondary p-button-outlined p-ml-2" />
                        </div>
                    </form>
                </Dialog>
            )}
        </div>
    );
};

export default GeneratedImage;
