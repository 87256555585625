import { httpDelete, httpGet, httpPost, httpPut } from './HttpService';
import axios from "axios";
export default class FeedbackService {
    private static instance: FeedbackService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new FeedbackService();
        }
        return this.instance;
    }

    async getListFeedback(page: number, limit: number, name: string) {
        const res = await httpGet(`/dashboard/feedback?page=${page}&limit=${limit}&name=${name}`);
        return res.data;
    }

    async getListGeneratedImage(page: number, limit: number, orderType?: 1 | 0 | -1 | undefined | null, order?: string | undefined, filter?: string | undefined, selectedCol?: string | null | undefined) {
        const res = await httpGet(`/dashboard/generated-image?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}&selectedCol=${selectedCol}`);
        return res.data;
    }

    async getListGeneratedImageAnime(page: number, limit: number, orderType: 1 | 0 | -1 | undefined | null, order: string | undefined, filter: string | undefined) {
        const res = await httpGet(`/dashboard/generated-image-anime?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}`);
        return res.data;
    }

    async getImageDetail(id: string) {
        const res = await httpGet(`/dashboard/generated-image-detail/${id}`);
        return res.data;
    }

    async getIdFromUrl(url: string) {
        const res = await httpGet(`/dashboard/get-id-from-url?url=${url}`);
        return res.data;
    }

    async getGroupData() {
        const res = await httpGet(`/dashboard/list-category`);
        return res.data;
    }

    async addTemplate(body: any) {
        const res = await httpPost(`/dashboard/add-template`, body);
        return res.data;
    }

    async getListOrder(page: number, limit: number, orderType?: 1 | 0 | -1 | undefined | null, order?: string | undefined, filter?: string | undefined, selectedCol?: string | null | undefined) {
        const res = await httpGet(`/dashboard/list-order?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}&selectedCol=${selectedCol}`);
        return res.data;
    }

    async getTemplate(page: number, limit: number, orderType?: 1 | 0 | -1 | undefined | null, order?: string | undefined, filter?: string | undefined, selectedCol?: string | null) {
        const res = await httpGet(`/dashboard/template?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}&selectedCol=${selectedCol}`);
        return res.data;
    }

    async getTemplateDetail(id: string) {
        const res = await httpGet(`/dashboard/template/${id}`);
        return res.data;
    }

    async updateTemplate(body: any) {
        const res = await httpPut(`/dashboard/template`, body);
        return res.data;
    }

    async deleteTempalte(id: string) {
        const res = await httpDelete(`/dashboard/template/${id}`);
        return res.data;
    }

    async getSignedUrl(fileName: string, fileType: string) {
        const res = await httpGet(`/dashboard/signed-url?fileName=${fileName}&fileType=${fileType}`);
        return res.data;
    }

    async addTemplateV2(body: any) {
        const res = await httpPost(`/dashboard/add-template-v2`, body);
        return res.data;
    }
    async importTemplateV3(body: any) {
        const res = await httpPost(`/dashboard/template-v3`, body);
        return res.data;
    }
    async uploadFileS3(file: any, signedUrl: string, url: string) {
        try {
            const headers = {
                'Content-Type': file.type,
                'Content-Length': file.size,
            };
            const response = await axios.put(signedUrl, file, { headers });
            if (response.status === 200) {
                return url;
            } else {
                throw new Error('Upload to S3 failed')
            }
        } catch (error) {
            throw new Error('Upload to S3 failed')
        }
    };

    async uploadImage(fileName: string, fileType: string) {
        const timestamp = new Date().getTime();
        try {
            const res = await httpGet(`/dashboard/signed-url?fileName=${timestamp}-${fileName}&fileType=${fileType}`);
            return res.data;
        } catch (error) {
            // Xử lý lỗi nếu có
            console.error("Error uploading image:", error);
            throw error; // Throw để chuyển tiếp lỗi cho phía gọi hàm xử lý
        }


    }
    async getListIAPNotify(page: number, limit: number, orderType?: 1 | 0 | -1 | undefined | null, order?: string | undefined, filter?: string | undefined) {
        const res = await httpGet(`/dashboard/list-iap-notify?page=${page}&limit=${limit}&order=${order}&filter=${filter}&orderType=${orderType}`);
        return res.data;
    }

    async resetDailyGen({ device_token }: { device_token: string }) {
        const res = await httpPost(`/dashboard/reset-daily-gen`, { device_token });
        return res.data;
    }
}