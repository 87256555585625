import FeedbackService from '../service/FeedbackService';

export const uploadFileToS3 = async (file: any, fileName: string, fileType: string) => {
    try {
        const urlInS3 = (await FeedbackService.getInstance().uploadImage(fileName, fileType)) as any;
        if (urlInS3) {
            const resultURL = (await FeedbackService.getInstance().uploadFileS3(file, urlInS3.data.signedRequest, urlInS3.data.url.trim())) as any;
            return resultURL;
        }
    } catch (error) {
        return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&s';
    }
};
interface ImageData {
    image: string;
    thumb: string;
}

interface MappedData {
    group: string;
    type: string;
    image_url: string;
    avatar_url: string;
    prompt: string;
    is_published: boolean;
    is_reward: number;
    generate_type: string;
}

export const convertData = (groupData: { name_group: string; data: ImageData[] }[]): MappedData[] => {
    const result: MappedData[] = [];

    // Iterate through each group
    groupData.forEach((group) => {
        const { name_group, data } = group;

        // Iterate through each item in the data array
        data.forEach((item) => {
            result.push({
                group: name_group,
                type: '',
                image_url: item.image, // Image as image_url
                avatar_url: item.thumb, // Thumb as avatar_url
                prompt: '',
                is_published: true,
                is_reward: 0,
                generate_type: ''
            });
        });
    });

    return result;
};
interface FormattedItem {
    group: string;
    type: string;
    avatar_url: string;
    image_url: string;
    prompt: string;
    is_published: boolean;
    generate_type: string;
}

interface FormattedItemHM {
    group: string;
    type: string;
    avatar_url: string;
    image_url: string;
    prompt: string;
    is_published: boolean;
    generate_type: string;
    is_reward: number;
}
interface ImageItem {
    group: string;
    image: string[];
    thumbnail: string[];
}
export function formatImages(input: ImageItem[], isPublic: boolean): FormattedItem[] {
    const output: FormattedItem[] = [];

    input.forEach((item) => {
        const { group, image, thumbnail } = item;

        image.forEach((img, index) => {
            output.push({
                group: group,
                image_url: img,
                avatar_url: thumbnail[index],
                generate_type: 'img2img',
                is_published: isPublic,
                prompt: '',
                type: ''
            });
        });
    });

    return output;
}
export function formatImagesHM(input: ImageItem[], isPublic: boolean): FormattedItemHM[] {
    const output: FormattedItemHM[] = [];

    input.forEach((item) => {
        const { group, image, thumbnail } = item;

        image.forEach((img, index) => {
            output.push({
                group: group,
                image_url: img,
                avatar_url: thumbnail[index],
                generate_type: 'img2img',
                is_published: isPublic,
                prompt: '',
                type: '',
                is_reward: 0
            });
        });
    });
    return output;
}
